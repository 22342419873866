<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑用户':'添加用户'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="720px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" :autoCheck='true' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16' lableRow labelPosition='left'>
					<template slot="tagsArrs" slot-scope="scope">
						<div>
							<TagBind :tagValueList="htmlDatas[scope.current.keys]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
						</div>
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms'> 提交</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TagBind from "@/components/TagBind";
	export default {
		props: {
			dataId: {
				type: String,
				default: '',
			},
			useType: {//使用类型， 选择 select 用户 或者手输 input 信息
				type: String,
				default: 'input',
			},
			enterpriseId: { //useType==select 时候 需要传企业ID enterpriseId
				type: String,
				default: '',
			},
			roleOptions:{
				type: Array,
				default:()=>{
					return []
				},
			},
			byEnterpriseEdit:{
				//是否来源于 企业详情  绑定用户
				type: Boolean,
				default: false,
			},
		},
		components: {TForm,TagBind},
		data() {
			return {
				sexOptions:[
					{name:'男',code:'1'},
  					{name:'女',code:'2'},
				],
				empRoleArrs:[],
				httpInfoData:{},//记录编辑的时候，通过ID获取后台返回的数据
				htmlDatas:{},
	  			formlist:[
	  				{name: '用户名称',keys: 'userName',value: '',type: 'input',flex:12,isMust:true,changMethod:this.userChange,options:[],},
	  				{name: '联系电话',keys: 'phone',value: '',type: 'input',flex:12,isMust:true,disabled:false},
	  				{name: ' 性别',keys: 'sex',value: '',type: 'select',flex:12,options:[],disabled:(this.useType=='select'||this.byEnterpriseEdit)},
	  				{name: '企业名称',keys: 'enterpriseId',value: '',type: 'select',options:[],flex:12,isMust:true,changMethod:this.handeChangeEnterprise,isHide:(this.useType=='select'||this.byEnterpriseEdit)},
	  				{name: '员工角色',keys: 'employeeRole',value: '',type: 'select',flex:12,isMust:true,options:[],},
	  				
	  				{name: '微信号',keys: 'wechatNumber',value: '',type: 'input',flex:12,disabled:(this.useType=='select'||this.byEnterpriseEdit)},
	  				{name: '微信昵称',keys: 'wechatName',value: '',type: 'input',flex:12,disabled:(this.useType=='select'||this.byEnterpriseEdit)},
	  				{name: '备注',keys: 'remark',value: '',type: 'textarea',flex:24},
	  			],
	  			enterpriseArrs:[],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					console.log('dataId', val);
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			enterpriseId: {
				deep: true, // 深度监听
				handler(val, oval) {
					console.log('enterpriseId', val);
					this.enterpriseChange(val);
				}
				
			},
			
		},
		created() {
			this.initEnterpriseArrs()
			this.resetTFrom();
			this.enterpriseChange(this.enterpriseId);
		},
		mounted() {
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			resetTFrom(){
				if(this.$refs.htmlFrom) this.$refs.htmlFrom.resetFields();
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				if(item.keys=='userName'){
	  					//区别判断是选择用户 自动填入信息 还是添加用户 手输信息
	  					item.type=this.useType;
	  					if(item.type=='select'){
	  						this.initUsersArrs();
	  					}
	  				}
	  				if(item.keys=='sex'){
	  					item.options =this.sexOptions
	  				}
	  				if(item.keys=='employeeRole'){
	  					item.options =this.roleOptions
	  				}
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			async getDatasById(){
				if(this.dataId){
		  			let res = await this.ApiHttp('/organization/userInfo/select/'+this.dataId);
					if(res){
						if(res.employeeRole) res.employeeRole=+res.employeeRole;
						this.httpInfoData=JSON.parse(JSON.stringify(res));
						this.htmlDatas={...this.htmlDatas,...res};
						this.enterpriseChange(res.enterpriseId,true);
						console.log('getDetailById',this.htmlDatas,res);
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			userChange(val,it){
				if(this.useType=='select'){
					for(let its of it.options){
						if(its.code===val){
							if(!its.employeeRole){
								this.empRoleArrs.map(it1=>{
									if(it1.default){
										its.employeeRole=it1.id;
									}
								})
							}
							this.htmlDatas={...its};
							return;
						}
						
					}
				}
				
			},
			
			handeChangeEnterprise(val){
				this.enterpriseChange(val);
			},
			async enterpriseChange(val,notCheck=false){
				//notCheck 后台获取数据的时候  不需要选中第一个
				if(!val) return;
				let params={
					current: 1,
					rowCount:99999,
					enterpriseId:val,
				}
				let res = await this.ApiHttp('/organization/authorityConfiguration/page',params);
				
				if(res) {
					res.rows=res.rows||[];
					res.rows.map(it=>{
						it.name=it.typeName;
	  					it.code=it.id;
					})
					if(!notCheck){
						res.rows.map(it1=>{
							if(it1.default){
								this.htmlDatas.employeeRole=it1.id;
							}
						})
					} 
					this.empRoleArrs=res.rows;
					this.common.insertOptions(this.formlist, 'employeeRole', res.rows);
					console.log('enterpriseChange22222222',res)
				}
				
				
			},
			async initUsersArrs(){
				//获取用户列表
	  			let params={
					current: 1,
					rowCount:99999,
					isBindEnterprise: 0,
				}
				let res = await this.ApiHttp('/organization/userInfo/page',params);
	  			if(res){
	  				res.rows.map(it=>{
	  					it.name=it.userName;
	  					it.code=it.userId;
	  				})
	  				console.log('未绑定用户列表',res.rows)
	  				this.common.insertOptions(this.formlist, 'userName', res.rows);
	  			}
				
			},
			async initEnterpriseArrs(){
				//获取企业列表
				let params={
					current: 1,
					rowCount:99999,
				}
				let res = await this.ApiHttp('/organization/enterprise/list',params);
	  			console.log('获取企业列表',res)
	  			if(res){
	  				this.enterpriseArrs=res||[];
	  				res.map(it=>{
	  					it.name=it.enterpriseName;
	  					if(it.name.length>20){
	  						it.name=it.name.slice(0,20)+'...';
	  					}
	  					it.code=it.enterpriseId;
	  				})
	  				this.common.insertOptions(this.formlist, 'enterpriseId', res);
	  			}
				
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
			},
			async getFromDatas(){
				let params={
					...this.htmlDatas,
				}
				let urlType='insert'
				if(this.dataId){
					urlType='update';//编辑
					if(this.byEnterpriseEdit){
						delete params.enterpriseId;
					}
					if(params.addDate){
						delete params.addDate;
					}
					if(this.httpInfoData.enterpriseId!==params.enterpriseId){
						params.addDate=new Date().getTime();
					}
				}else{
					params.isForbidden=0;
					params.source='管理后台';
				}
				if(this.useType=='select'){
					urlType='update';//编辑
					this.userBindToEnterprise();
					return;
				}
				let res = await this.ApiHttp('/organization/userInfo/'+urlType,params,'post');
				if(res){
					this.$emit('close','init')
					console.log('validateFroms',res)
				}
			},
			async userBindToEnterprise(){
				//用户绑定到企业下
				let params={
					enterpriseId:this.enterpriseId,
					userId:this.htmlDatas.userId,
					remark:this.htmlDatas.remark,
					phone:this.htmlDatas.phone,
					addDate:new Date().getTime(),
					employeeRole:this.htmlDatas.employeeRole,
				}
				let res = await this.ApiHttp('/organization/userInfo/userBindEnterprise',params,'post');
				if(res){
					this.$emit('close','init')
					console.log('validateFroms',res)
				}
				
			},
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>